class MenuButtonHandler {
    constructor(buttonSelector) {
        this.angebotButtons = document.querySelectorAll(buttonSelector);
        this.scrollToSection = this.scrollToSection.bind(this);
        this.angebotButtons.forEach(button => {
            button.addEventListener('click', this.scrollToSection);
        });
    }

    scrollToSection(event) {
        event.preventDefault();
        const targetSectionId = event.target.closest('.header__button--untermenue').getAttribute('href'); // Auf event.target zugreifen
        const targetSection = document.querySelector(targetSectionId);
        const targetSectionTop = targetSection.getBoundingClientRect().top + window.scrollY;
        const targetPosition = targetSectionTop;

        window.scrollTo({
            top: targetPosition,
            left: 0,
            behavior: 'smooth'
        });
    }
}

document.addEventListener('DOMContentLoaded', function () {
    const menuButtonHandler = new MenuButtonHandler('.header__button--untermenue');
});


// HEADER FIXIEREN
const header = document.getElementById('header');
const angebotContainer = document.querySelector('.angebot__angebot-container');
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.create({
    trigger: angebotContainer,
    pin: header,
    pinSpacing: false,
    start:() => 'top top',
    // markers: true
});

const angebotIntro = document.getElementById('angebot__intro');
if (angebotIntro !== null) {
    angebotIntro.addEventListener('mouseover', function() {
        document.body.removeAttribute('data-maushintergrund');
    });
}